import React, { Component } from "react";
import {
  withStyles,
  Box,
  Grid,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  Snackbar,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  Select,
  MenuItem,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {
  Add,
  ArrowBack,
  Clear,
  Edit,
  FiberManualRecord,
  Person,
  Info,
  ArrowForward,
  Close,
  InfoOutlined,
} from "@material-ui/icons";
import BillingPage from "../BillingProfile/BillingPage";
import TemplateRoute from "../TemplateMsg/TemplateRoute";
import { connect } from "react-redux";
import axios from "axios";
import {
  URL,
  projectURL,
  partnerURL,
  META_ADS_PARTNER,
  APIURL,
} from "../../config/config";
import { editAssistants } from "../../store/assistantState";
import { Alert, Autocomplete } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import WebhookMappingsTable from "./webhookMappingsTable";
import plansHelper from "../../helpers/plansHelper";
import WabaForm from "../Dashboard/WabaForm/WabaForm";
import AgentLimitDialog from "../Manage/AgentLimitDialog";
import WccAnalytics from "./WccAnalytics/WccAnalytics";
import StepBasedSolutions from "../../commons/Troubleshoot/StepBasedSolutions";
import SetupMetaAds from "../MetaAds/SetupMetaAds";
import AddonsManagement from "./Tabs/AddonsManagement";
import { fetchPartnerDetails } from "../../store/partnerState";
import DefaultsPage from "../Manage/DefaultsPage";
import PlanSetup from "./Tabs/PlanSetup";
import Header from "../../commons/Header/Header";
import { checkAccess } from "../../helpers/RBAC";
import moment from "moment";
import KycDialog from "./KycDialog/KycDialog";
import { uploadMedia } from "../../commons/MediaLibrary/mediaState";

const verticals = [
  "UNDEFINED",
  "OTHER",
  "AUTO",
  "BEAUTY",
  "APPAREL",
  "EDU",
  "ENTERTAIN",
  "EVENT_PLAN",
  "FINANCE",
  "GROCERY",
  "GOVT",
  "HOTEL",
  "HEALTH",
  "NONPROFIT",
  "PROF_SERVICES",
  "RETAIL",
  "TRAVEL",
  "RESTAURANT",
  "NOT_A_BIZ",
];
const PLANS = [
  "FREE",
  "LITE",
  "PRO",
  "PLUS",
  "PREMIUM",
  "BASIC_MONTHLY",
  "BASIC_QUARTERLY",
  "BASIC_YEARLY",
  "PRO_MONTHLY",
  "PRO_QUARTERLY",
  "PRO_YEARLY",
  "ENTERPRISE_MONTHLY",
  "ENTERPRISE_QUARTERLY",
  "ENTERPRISE_YEARLY",
  "BASIC_MONTHLY_TIER_1",
  "BASIC_MONTHLY_TIER_2",
  "BASIC_MONTHLY_TIER_3",
  "BASIC_MONTHLY_TIER_4",
  "BASIC_MONTHLY_TIER_5",
  "BASIC_MONTHLY_TIER_6",
  "BASIC_MONTHLY_TIER_7",
  "BASIC_MONTHLY_TIER_8",
  "BASIC_YEARLY_TIER_1",
  "BASIC_YEARLY_TIER_2",
];

const PROJECT_STATUS = [
  "active",
  "unpaid",
  "terminated",
  "pending",
  "suspended",
  "stopped",
  "archived",
];

function getStatusColor() {
  const templateStatus = "PENDING";
  switch (templateStatus) {
    case "PENDING":
      return "grey";
    case "APPROVED":
      return "#08CF65";
    case "REJECTED":
      return "#ff0000";
    default:
      return "pink";
  }
}

const allTopics = [
  "message.created",
  "message.status.updated",
  "message.sender.user",
  "contact.created",
  "contact.attribute.updated",
  "contact.chat.intervened",
  "contact.chat.requesting",
  "contact.chat.closed",
  "contact.campaign.sent",
  "contact.campaign.read",
  "contact.first_message.added",
  "contact.first_message.removed",
  "contact.tag.added",
  "contact.tag.removed",
];

class AssistantsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertType: "",
      alertMsg: "",
      tab: "Setup",
      kycDialog: false,
      fbmName: "",
      fbmStatus: "",
      assistant: null,
      editProfilePicture: false,
      editAboutText: false,
      editAddress: false,
      editDescription: false,
      editEmail: false,
      editWebsite: false,
      editBusinessVertical: false,
      newProfilePicture: "",
      newAboutText: "",
      newAddress: "",
      newDescription: "",
      newEmail: "",
      newWebsite: "",
      newBusinessVertical: "",
      loadProfilePicture: false,
      loadAboutText: false,
      loadAddress: false,
      loadDescription: false,
      loadEmail: false,
      loadWebsite: false,
      loadBusinessVertical: false,
      selectedFile: {},
      wasImageChanged: false,
      openPlanDialog: false,
      allPlans: [],
      changePlan: "",
      planSubmitLoader: false,
      openWebhookDialog: false,
      webhookSubmitLoader: false,
      selectedTopic: [],
      selectedWebhookurl: "",
      webhookResult: [],
      isWebhookLoading: true,
      openWccDialog: false,
      openAdCreditDialog: false,
      wccAction: "",
      wccAmount: "",
      adCreditAction: "",
      adCreditAmount: "",
      wccSubmitLoader: false,
      adCreditSubmitLoader: false,
      openPwcDialog: false,
      pwcAction: "",
      pwcAmount: "",
      pwcSubmitLoader: false,
      linkGenerated: "",
      migrationLinkGenerated: "",
      manageCatalogLinkGenerated: "",
      tspMigrationLink: "",
      selectedFamilyId: null,
      currentPlan: "",
      email: "",
      contact: "",
      company: "",
      reqBusinessId: "",
      planStoppedOn: false,
      selectedValue: "a",
      disableDate:
        Number(
          localStorage.getItem(
            `resetField_${this.props.match.params.projectId}`
          )
        ) + 300000,
      openWccChangeDialog: false,
      handleClickDialog: false,
      openPlanDialog: false,
      wabaDialog: false,
      showAgentDialog: false,
      metaError: null,
      isLoading: false,
      openPrivateAppDialog: false,
      privateApps: [],
      password: "",
      webhookUrl: "",
      SharedSecret: "",
      isWebhookLoading: false,
      chatApiWebhook: {},
      reactivationLoader: false,
      deleteProject: false,
      deleteMessage: null,
      deleteStatus: null,
      deleteLoading: false,
      reportLoading: false,
      isTspWabaId: true,
    };
  }

  fecthAssistant = async () => {
    axios
      .get(URL + `/api/get-project/${this.props.match.params.projectId}`)
      .then((response) => {
        const assistants = response.data.projects;
        this.setState({ assistant: assistants[0] }, () => {
          this.fetchFbmDetails();
          this.setState({
            addonCheck: this.state?.assistant?.addons?.WEBHOOKS ? true : false,
          });
        });
        if (assistants[0].isWhatsappVerified) this.getDirectApiPartnerProfile();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  updateAssistant = (values) => {
    const existing = this.state.assistant;
    this.setState({ assistant: { ...existing, ...values } });
  };

  fetchFbmDetails = async () => {
    const payload = {
      fbBusinessId: this.state.assistant?.fbBusinessId || "",
      wabaId: this.state.assistant?.wabaAppId || "",
    };

    axios
      .get(partnerURL + "/partner/fetch-fbm-meta-info", {
        params: payload,
      })
      .then((response) => {
        this.setState({
          fbmName: response.data?.businessInfo?.name,
          fbmStatus: response.data?.businessInfo?.verification_status,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.props.fetchPartnerDetails();
    this.fecthAssistant();
    this.fetchBusiness();
    this.fetchBusinessDetails();
    if (this.props.partner?.type !== "DIRECT") {
      this.fetchPrivateApp();
      plansHelper.setAllPlans();
      this.getPartnerPlans();
      this.fetchWebhook();
    }
  }

  getPartnerPlans = () => {
    axios
      .get(
        URL +
          `/api/get-partner-plan-family/${this.props.match.params.projectId}`
      )
      .then((response) => {
        const data = response.data.family.plans;
        this.setState({
          selectedFamilyId: response.data.family._id.toString(),
          allPlans: data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  closeHandleDialog = () => {
    this.setState({ handleClickDialog: !this.state.handleClickDialog });
  };

  fetchBusiness() {
    // const partnerId = this.props.user.id;
    const _id = this.props.match.params.projectId;
    axios
      .get(URL + `/api/get-business-details/${_id}`)
      .then((response) => {
        const data = response.data;
        this.setState({
          email: data.email,
          contact: data.contact,
          company: data.company,
          reqBusinessId: data._id,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchBusinessDetails = () => {
    const assistantId = this.props.match.params.projectId;
    axios
      .get(URL + `/api/get-phone-details/${assistantId}`)
      .then((response) => {
        const data = response.data;
        this.setState({
          isTspWabaId: data.success,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  goToBusiness = () => {
    this.props.history.push(`/businesses/${this.state.reqBusinessId}`);
  };

  editToggle = (edit) => {
    this.setState({
      [edit]: !this.state[edit],
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  syncWaba = () => {
    this.setState({ planSubmitLoader: true });
    const id = this.props.match.params.projectId;
    const partnerId = this.props.partner._id;
    const checkTime = localStorage.getItem(`resetField_${id}`);
    const resetCheck = Number(checkTime) + 300000;
    if (checkTime) {
      if (resetCheck > Date.now()) {
        this.setState({
          alertType: "error",
          alertMsg: "Please try to sync after few miniutes",
        });
        return;
      }
    }
    axios
      .post(partnerURL + `/partner/${partnerId}/sync-channel-status`, {
        assistantId: id,
      })
      .then((response) => {
        this.setState({
          alertType: "success",
          alertMsg: "Sync successful",
          planSubmitLoader: false,
          disableDate: Date.now() + 30000,
        });
        localStorage.setItem(`resetField_${id}`, Date.now());
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg: "Sync failed",
          planSubmitLoader: false,
        });
      });
  };

  onEdit = async (item) => {
    try {
      const { assistant, wasImageChanged, selectedFile } = this.state;
      const keys = {
        newAboutText: ["whatsAppAbout", "loadAboutText", "editAboutText"],
        newAddress: ["address", "loadAddress", "editAddress"],
        newDescription: ["description", "loadDescription", "editDescription"],
        newEmail: ["email", "loadEmail", "editEmail"],
        newWebsite: ["websites", "loadWebsite", "editWebsite"],
        newBusinessVertical: [
          "vertical",
          "loadBusinessVertical",
          "editBusinessVertical",
        ],
        newProfilePicture: [
          "whatsAppDisplayImage",
          "loadProfilePicture",
          "editProfilePicture",
        ],
      };
      const { whatsAppAbout, whatsAppBusinessProfile } = assistant || {};

      const { address, description, email, vertical, websites } =
        whatsAppBusinessProfile || {};

      const queryObj = {
        assistantId: this.props.match.params.projectId,
        address: address,
        description: description,
        email: email,
        vertical: vertical,
        wasImageChanged: false,
        filePath: "",
        websites: websites,
        whatsAppAbout: whatsAppAbout,
        whatsAppDisplayImage: "",
      };

      let result;

      if (item === "newProfilePicture") {
        if (this.state.loadProfilePicture) return;
      } else {
        if (!this.state[item] || this.state[keys[item][1]]) {
          return;
        }
        if (item === "newWebsite") result = [this.state[item]];
        else result = this.state[item];
        queryObj[keys[item][0]] = result;
      }

      this.setState({
        [keys[item][1]]: true,
      });

      if (wasImageChanged && selectedFile) {
        // Get Upload-URL
        const response = await axios.post(
          projectURL + `/project/${queryObj.assistantId}/get-upload-url`,
          {
            assistantId: queryObj.assistantId,
            fileName: "profile_picture_" + Date.now(),
            contentType: selectedFile.type,
            fileType: "IMAGE",
          }
        );
        let form = new FormData();
        Object.entries(response.data.uploadURL.fields).forEach((entry) => {
          form.append(entry[0], entry[1]);
        });
        form.append("file", selectedFile);
        const config = {
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return data;
          },
        };
        await axios.post(response.data.uploadURL.url, form, config);
        const fileURL = response.data.fullUrl;
        const filePath = response.data.filePath;
        queryObj.wasImageChanged = true;
        queryObj.whatsAppDisplayImage = fileURL;
        queryObj.filePath = filePath;
        queryObj.type = selectedFile?.type;
        queryObj.size = selectedFile?.size;
        result = fileURL;
      }

      axios
        .post(
          projectURL +
            `/project/${queryObj.assistantId}/update-business-profile`,
          queryObj
        )
        .then((response) => {
          this.setState({
            alertType: "success",
            alertMsg: "Successfully Updated",
            wasImageChanged: false,
            selectedFile: {},
          });
          this.props.editAssistants(
            queryObj.assistantId,
            keys[item][0],
            result
          );

          const updateState = {
            ...this.state.assistant,
          };

          if (item === "newAboutText" || item === "newProfilePicture") {
            updateState[keys[item][0]] = result;
          } else {
            const whatsAppBusinessProfile = {
              ...this.state.assistant.whatsAppBusinessProfile,
              [keys[item][0]]: result,
            };
            updateState.whatsAppBusinessProfile = whatsAppBusinessProfile;
          }

          this.setState({
            [keys[item][1]]: false,
            [keys[item][2]]: false,
            assistant: updateState,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            alertType: "error",
            alertMsg:
              error?.response?.data?.message ||
              "something went wrong, tryagain",
          });
          this.setState({
            [keys[item][1]]: false,
            [keys[item][2]]: false,
          });
        });
    } catch (error) {
      console.log(error);
      this.setState({
        alertType: "error",
        alertMsg:
          error?.response?.data?.message || "something went wrong, tryagain",
      });
    }
  };

  openFileUploader = (action) => {
    this.setState({ editProfilePicture: true }, () => {
      if (this.props.partner?.type !== "DIRECT") {
        const input = document.createElement("input");
        input.id = "file_uploader";
        input.type = "file";
        input.accept = "image/jpeg, image/png";
        input.addEventListener("change", this.uploadFile);
        input.click();
      }
    });
  };

  uploadFile = (input) => {
    const files = input.target?.files;
    if (files && files[0]) {
      const file = files[0];
      const MB_1 = 1000000;

      //  Max size of 5MB allowed.
      if (file.size > 5 * MB_1) {
        this.setState({
          alertOpen: true,
          alertType: "error",
          alertMsg: "Maximum size of Image can be 5MB",
        });
      } else {
        var reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          const updateState = (data) => this.setState(data);
          // const updateForm = (key, value) => this.updateForm(key, value);
          img.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height < 192 || width < 192) {
              updateState({
                alertOpen: true,
                alertType: "error",
                alertMsg: "Image size too small",
              });
            } else {
              updateState({
                wasImageChanged: true,
                selectedFile: file,
              });
            }
          };
          img.onerror = () => {
            this.setState({
              alertOpen: true,
              alertType: "error",
              alertMsg: "Invalid file format",
            });
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
        const input = document.getElementById("file_uploader");
        input.removeEventListener("change", this.uploadFile);
      }
    }
  };

  updateProfileViaDirectAPI = (key, value, toggleKey) => {
    axios
      .patch(URL + `/api/direct-api-partner/profile`, {
        projectId: this.props.match.params.projectId,
        [key]: value,
      })
      .then((_) => {
        this.setState({
          alertType: "success",
          alertMsg: "Updated successfully",
          [toggleKey]: !this.state[toggleKey],
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, try again",
        });
      });
  };

  getDirectApiPartnerProfile = () => {
    const projectId = this.props.match.params.projectId;
    axios
      .get(URL + `/api/direct-api-partner/profile/${projectId}`)
      .then(({ data }) => {
        const { businessProfile } = data;
        const [profile] = businessProfile;

        const {
          about: newAboutText,
          address: newAddress,
          description: newDescription,
          email: newEmail,
          profile_picture_url: newProfilePicture,
          websites,
          vertical: newBusinessVertical,
        } = profile;

        this.setState({
          newProfilePicture,
          newAboutText,
          newAddress,
          newDescription,
          newEmail,
          newWebsite: websites?.join(", ") || "",
          newBusinessVertical,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, try again",
        });
      });
  };

  planDialog = () => {
    this.setState({ openPlanDialog: !this.state.openPlanDialog });
  };

  toggleWccPlanChangeDialog = () => {
    this.setState({ openWccChangeDialog: !this.state.openWccChangeDialog });
  };

  wccDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openWccDialog: !this.state.openWccDialog });
  };

  adCreditDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openAdCreditDialog: !this.state.openAdCreditDialog });
  };

  pwcDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openPwcDialog: !this.state.openPwcDialog });
  };

  webhookDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openWebhookDialog: !this.state.openWebhookDialog });
  };

  privateAppDialog = (event, reason) => {
    reason !== "backdropClick" &&
      this.setState({ openPrivateAppDialog: !this.state.openPrivateAppDialog });
  };

  fetchWebhook = () => {
    this.setState({ isWebhookLoading: true });
    axios
      .get(
        projectURL +
          `/project/${this.props.match.params.projectId}/partner/webhook`
      )
      .then((response) => {
        let webhooks = response.data;
        webhooks.forEach((assistant) => {
          if (
            assistant.topics.includes("message.sender.user") ||
            assistant.topics.includes("message.status.updated")
          ) {
            this.setState({ chatApiWebhook: assistant });
          }
        });
        this.setState({ webhookResult: webhooks, isWebhookLoading: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isWebhookLoading: false });
      });
  };

  fetchPrivateApp = () => {
    this.setState({ isLoading: true });
    axios
      .get(
        projectURL + `/project/${this.props.match.params.projectId}/private-app`
      )
      .then((response) => {
        let privateApps = response.data;
        this.setState({
          privateApps: privateApps,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  CreatePrivateApp = async () => {
    this.setState({ isLoading: true });
    const { partnerId, clientId } = this.state.assistant;
    axios
      .post(
        projectURL +
          `/project/${this.props.match.params.projectId}/private-app`,
        { partnerId, clientId }
      )
      .then((response) => {
        let privateApps = response.data;
        // let webhooksObj = {};
        // webhooks.forEach((assistant) => {
        //   webhooksObj[assistant.project_id] = assistant;
        // });
        this.setState({ password: privateApps, isLoading: false });
        this.setState({
          isLoading: false,
          alertType: "success",
          alertMsg: "Private app created",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          alertType: "error",
          alertMsg: error.response.data.message || "Something went wrong",
        });
      });
  };

  updatePrivateApp = async () => {
    this.setState({ isLoading: true });
    const { _id } = this.state.privateApps;
    axios
      .patch(
        projectURL +
          `/project/${this.props.match.params.projectId}/private-app`,
        { privateAppId: _id }
      )
      .then((response) => {
        let reqPassword = response.data;
        this.setState({
          password: reqPassword,
          isLoading: false,
          alertType: "success",
          alertMsg: "Private app updated",
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          isLoading: false,
          alertType: "error",
          alertMsg: error.response.data.message || "Something went wrong",
        });
      });
  };

  planSubmit = async (newFamilyId, newPlanId) => {
    const newObj = {
      ...this.state.assistant,
    };
    try {
      const resonse = await axios.patch(
        partnerURL +
          `/partner/${this.state.assistant.partnerId}/project/${this.props.match.params.projectId}/billing/switch-plan`,
        {
          newFamilyId,
          newPlanId,
        }
      );

      if (resonse.status === 200) {
        newObj.activePlan = newPlanId;
        newObj.familyId = newFamilyId;
        this.props.editAssistants(newObj._id, "familyId", newFamilyId);
        this.props.editAssistants(newObj._id, "activePlan", newPlanId);

        const reqPlan = this.state.changePlan;
        this.setState({
          alertType: "success",
          alertMsg: "Successfully updated plan",
          planSubmitLoader: false,
          openPlanDialog: false,
          assistant: newObj,
          currentPlan: reqPlan,
        });
      }
    } catch (error) {
      this.setState({
        alertType: "error",
        alertMsg:
          error?.response?.data?.message || "something went wrong, try again",
        planSubmitLoader: false,
      });
    }
  };

  changeWccPlan = async (wccPlanId) => {
    const newObj = {
      ...this.state.assistant,
    };
    try {
      const response = await axios.patch(URL + "/api/update-project-wcc-plan", {
        assistantId: this.props.match.params.projectId,
        wccPlanId,
      });

      if (response.status === 200) {
        newObj.templateTier = wccPlanId;
        this.props.editAssistants(newObj._id, "templateTier", wccPlanId);
        this.setState({
          alertType: "success",
          alertMsg: "Successfully changed wcc plan",
          planSubmitLoader: false,
          openWccChangeDialog: false,
          assistant: newObj,
        });
      }
    } catch (error) {
      this.setState({
        alertType: "error",
        alertMsg:
          error?.response?.data?.message || "something went wrong, try again",
        planSubmitLoader: false,
      });
    }
  };

  // to stop the plan
  stopPlan = () => {
    this.setState({
      planSubmitLoader: true,
    });
    axios
      .patch(
        partnerURL +
          `/partner/${this.state.assistant.partnerId}/stop-project-billing/${this.props.match.params.projectId}`
      )
      .then((response) => {
        this.setState({
          alertType: "success",
          alertMsg: "Plan stopped!",
          planStoppedOn: true,
          planSubmitLoader: false,
        });
        this.closeHandleDialog();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, try again",
          planSubmitLoader: false,
        });
      });
  };

  webhookSubmit = () => {
    this.setState({ webhookSubmitLoader: true });
    const newObj = {
      topics: this.state.selectedTopic,
      webhook_url: this.state.selectedWebhookurl,
    };

    axios
      .post(
        projectURL + `/project/${this.props.match.params.projectId}/webhook`,
        newObj
      )
      .then((response) => {
        this.setState(
          {
            alertType: "success",
            alertMsg: "Successfully created webhook",
            webhookSubmitLoader: false,
            openWebhookDialog: false,
          },
          this.fetchWebhook
        );
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, tryagain",
          webhookSubmitLoader: false,
        });
      });
  };

  createWebhook = () => {
    this.setState({ isWebhookLoading: true });
    const newObj = {
      topics: ["message.sender.user", "message.status.updated"],
      webhook_url: this.state.webhookUrl,
    };

    axios
      .post(
        projectURL + `/project/${this.props.match.params.projectId}/webhook`,
        newObj
      )
      .then((response) => {
        this.setState(
          {
            alertType: "success",
            alertMsg: "Successfully created webhook",
            isWebhookLoading: false,
          },
          this.fetchWebhook
        );
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, tryagain",
          isWebhookLoading: false,
        });
      });
  };

  adCreditSubmit = () => {
    this.setState({ adCreditSubmitLoader: true });
    const newObj = {
      ...this.state.assistant,
    };
    if (this.state.adCreditAmount && this.state.adCreditAmount > 0)
      axios
        .patch(
          partnerURL +
            `/partner/${this.state.assistant.partnerId}/project/${this.props.match.params.projectId}/billing/adCredit`,
          {
            amount: this.state.adCreditAmount,
            action: this.state.adCreditAction,
          }
        )
        .then((response) => {
          newObj.metaAdsCredit = response.data.credit;
          this.props.editAssistants(
            newObj._id,
            "metaAdsCredit",
            response.data.credit
          );
          this.setState({
            alertType: "success",
            alertMsg: "Successfully updated Ad Credit",
            adCreditSubmitLoader: false,
            openAdCreditDialog: false,
            assistant: newObj,
          });
        })
        .catch((error) => {
          this.setState({
            alertType: "error",
            alertMsg:
              error?.response?.data?.message ||
              "something went wrong, tryagain",
            adCreditSubmitLoader: false,
          });
        });
    else
      this.setState({
        alertType: "error",
        alertMsg: "Invalid amount",
        adCreditSubmitLoader: false,
      });
  };

  wccSubmit = () => {
    this.setState({ wccSubmitLoader: true });
    const newObj = {
      ...this.state.assistant,
    };
    if (this.state.wccAmount && this.state.wccAmount > 0)
      axios
        .patch(
          partnerURL +
            `/partner/${this.state.assistant.partnerId}/project/${this.props.match.params.projectId}/billing/wcc`,
          {
            amount: this.state.wccAmount,
            action: this.state.wccAction,
          }
        )
        .then((response) => {
          newObj.templateCredit = response.data.credit;
          this.props.editAssistants(
            newObj._id,
            "templateCredit",
            response.data.credit
          );
          this.setState({
            alertType: "success",
            alertMsg: "Successfully updated Wcc",
            wccSubmitLoader: false,
            openWccDialog: false,
            assistant: newObj,
          });
        })
        .catch((error) => {
          this.setState({
            alertType: "error",
            alertMsg:
              error?.response?.data?.message ||
              "something went wrong, tryagain",
            wccSubmitLoader: false,
          });
        });
    else
      this.setState({
        alertType: "error",
        alertMsg: "Invalid amount",
        wccSubmitLoader: false,
      });
  };

  pwcSubmit = () => {
    this.setState({ pwcSubmitLoader: true });
    const newObj = {
      ...this.state.assistant,
    };
    if (this.state.pwcAmount && this.state.pwcAmount > 0)
      axios
        .patch(
          partnerURL +
            `/partner/${this.state.assistant.partnerId}/project/${this.props.match.params.projectId}/billing/pwc`,
          {
            amount: this.state.pwcAmount,
            action: this.state.pwcAction,
          }
        )
        .then((response) => {
          newObj.partnerWhatsappCredit = response.data.partner_credit;
          this.props.editAssistants(
            newObj._id,
            "partnerWhatsappCredit",
            response.data.partner_credit
          );
          this.setState({
            alertType: "success",
            alertMsg: "Successfully updated Wcc",
            pwcSubmitLoader: false,
            openPwcDialog: false,
            assistant: newObj,
          });
        })
        .catch((error) => {
          this.setState({
            alertType: "error",
            alertMsg:
              error?.response?.data?.message ||
              "something went wrong, tryagain",
            pwcSubmitLoader: false,
          });
        });
    else
      this.setState({
        alertType: "error",
        alertMsg: "Enter a valid amount value",
        pwcSubmitLoader: false,
      });
  };

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  setSnackbar = ({ alert, alertType, alertMsg }) => {
    this.setState({
      alert,
      alertType,
      alertMsg,
    });
  };

  generateWabaLink = () => {
    const assistant = this.state.assistant;
    const { clientId, partnerId, _id } = assistant;
    axios
      .post(URL + `/api/generate-waba-link/`, {
        assistantId: _id,
        businessId: clientId,
        partnerId,
      })
      .then((response) => {
        const { link } = response.data;
        this.setState({
          linkGenerated: link,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  generateWabaMigrationLink = () => {
    const assistant = this.state.assistant;
    const { partnerId, _id } = assistant;
    axios
      .post(URL + `/api/generate-waba-migration-link/`, {
        assistantId: _id,
        partnerId,
      })
      .then((response) => {
        const { link } = response.data;
        this.setState({
          migrationLinkGenerated: link,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  generateTSPMigrationLink = () => {
    const assistant = this.state.assistant;
    const { partnerId, _id } = assistant;
    axios
      .post(URL + `/api/generate-waba-migration-link/`, {
        assistantId: _id,
        partnerId,
        isTSP: true,
      })
      .then((response) => {
        const { link } = response.data;
        this.setState({
          tspMigrationLink: link,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  generateManageCatalogLink = () => {
    const assistant = this.state.assistant;
    const { partnerId, _id } = assistant;
    axios
      .post(URL + `/api/generate-connect-catalog-link/`, {
        assistantId: _id,
        partnerId,
      })
      .then((response) => {
        const { link } = response.data;
        this.setState({
          manageCatalogLinkGenerated: link,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleChange = (event) => {
    this.setState({
      selectedValue: event.target.value,
    });
  };

  toggleWABADialog = () => {
    this.setState({ wabaDialog: !this.state.wabaDialog });
  };

  openWabaDialog = () => {
    if (!this.state.assistant.apiFormDetails) {
      this.setState({
        alertType: "error",
        alertMsg: "API form details are not available",
      });
    } else {
      this.setState({
        wabaDialog: true,
      });
    }
  };

  toggleAgentDialog = () => {
    this.setState({ showAgentDialog: !this.state.showAgentDialog });
  };

  updateAgentLimit = (agentSize) => {
    const assistant = this.state.assistant;
    const { _id } = assistant;
    axios
      .patch(URL + `/api/update-project-agent-size`, {
        projectId: _id,
        agentSize,
      })
      .then((response) => {
        this.setState({
          alertType: "success",
          alertMsg: "Agent size updated successfully",
          showAgentDialog: false,
          assistant: { ...this.state.assistant, maxAgents: agentSize },
        });
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg: "Agent size updation failed! Something went wrong.",
        });
        console.error(error);
      });
  };

  EmbeddedSignup = () => {
    const { classes } = this.props;
    return (
      <Box py={2} px={2} my={2} className={classes.container}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Embedded SignUp URL
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: window.innerWidth < 767 ? "center" : "flex-end",
            }}
          >
            {this.state.linkGenerated ? (
              <Box className={classes.urlWrapper}>
                <Box className={classes.urlLink}>
                  {this.state.linkGenerated}
                </Box>
                <Box
                  className={classes.copyLink}
                  onClick={() => {
                    this.setState({
                      alertType: "success",
                      alertMsg: "URL copied to clipboard",
                    });
                    navigator.clipboard.writeText(this.state.linkGenerated);
                  }}
                >
                  Copy Link
                </Box>
              </Box>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={this.generateWabaLink}
              >
                Generate URL
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  EmbeddedMigration = () => {
    const { classes } = this.props;
    return (
      <Box py={2} px={2} my={2} className={classes.container}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              WhatsApp Business API Account Migration URL
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: window.innerWidth < 767 ? "center" : "flex-end",
            }}
          >
            {this.state.migrationLinkGenerated ? (
              <Box className={classes.urlWrapper}>
                <Box className={classes.urlLink}>
                  {this.state.migrationLinkGenerated}
                </Box>
                <Box
                  className={classes.copyLink}
                  onClick={() => {
                    this.setState({
                      alertType: "success",
                      alertMsg: "URL copied to clipboard",
                    });
                    navigator.clipboard.writeText(
                      this.state.migrationLinkGenerated
                    );
                  }}
                >
                  Copy Link
                </Box>
              </Box>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={this.generateWabaMigrationLink}
              >
                Generate URL
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  SyncProgress = () => {
    const { classes } = this.props;
    return (
      <Box py={2} px={2} my={2} className={classes.container}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Sync Waba Procurement Progress
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: window.innerWidth < 767 ? "center" : "flex-end",
            }}
          >
            <Tooltip
              title={
                Number(this.state.disableDate) - Date.now() >= 0 ||
                this.state.planSubmitLoader
                  ? "Retry again a in few mins"
                  : "Sync WABA"
              }
            >
              <span>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.syncWaba}
                  disabled={
                    Number(this.state.disableDate) - Date.now() >= 0 ||
                    this.state.planSubmitLoader
                  }
                  endIcon={
                    this.state.planSubmitLoader ? (
                      <CircularProgress size={20} />
                    ) : (
                      ""
                    )
                  }
                >
                  Sync
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    );
  };

  setMetaError = (error) => {
    this.setState({ metaError: error });
  };

  embeddedHelper = () => {
    const { assistant, metaError } = this.state;
    let { wabaProgressStep, procurementError, isWhatsappVerified } =
      assistant || {};
    if (this.state.assistant && !wabaProgressStep) {
      wabaProgressStep = 0;
    }

    let { process, error } = metaError || procurementError || {};
    if (metaError && metaError?.response?.data?.error?.code) {
      error = metaError;
    }
    let steps = [];
    let customObject = { component: <></>, title: "", message: "" };

    if (wabaProgressStep === 9 && isWhatsappVerified) {
      steps = ["register_pin", "otp_verification"];
    }

    if (wabaProgressStep <= 7) {
      steps = [
        "health_check",
        "register_pin",
        "otp_verification",
        "embedded_signup",
        "sync_progress",
        // "migrate_partner_project"
      ];

      const code = error?.response?.data?.error?.code;
      const subCode = error?.response?.data?.error?.error_subcode;
      // If we have error available then use it to suggest further steps
      if (wabaProgressStep < 7) {
        switch (process) {
          case "partner_embedded_signup":
            //Facebook Authentication failed!
            steps = ["health_check", "embedded_signup"];
            break;
          case "partner_waba_migration":
            steps = ["health_check", "migrate_partner_project"];
            break;
          default:
            break;
        }
      }
      // If we have error available then use it to suggest further steps
      else if (code === 100) {
        // "Phone numbers count exceeded limit per business.
        if (subCode === 2388112) {
          steps = [
            "health_check",
            process === "migrate_partner_project"
              ? "migrate_partner_project"
              : "embedded_signup",
          ];
        }
        // Incorrect/Unsupported APP ID.
        else if (subCode === 33) {
          steps = [
            "custom_msg",
            process === "migrate_partner_project"
              ? "migrate_partner_project"
              : "embedded_signup",
          ];
          customObject = {
            component: <></>,
            title: "Incorrect/Unsupported WhatsApp Business Account ID",
            message:
              "The WhatsApp Business Account ID used during the onboarding process is either Incorrect or Unsupported. Kindly go through onboarding again with  a new Account ID",
          };
        }
        // Fallback
        else {
          steps = [];
        }
      } else if (code === 133005) {
        //2 Factor Authentication is enabled
        steps = [
          "custom_msg",
          "register_pin",
          "otp_verification",
          "health_check",
          "sync_progress",
        ];
        customObject = {
          component: <></>,
          title: "Disable 2FA",
          message:
            "Your 2 Factor Authentication is enabled. Please disable 2 Factor Authentication first and then proceed to register your phone number in the next step.",
        };
      } else if (code === 133006) {
        //Phone number re-verification needed
        if (subCode === 2494081) {
          steps = [
            "custom_msg",
            "otp_verification",
            "register_pin",
            "health_check",
            "sync_progress",
          ];
        }

        customObject = {
          component: <></>,
          title: "Phone number re-verification needed",
          message:
            "Phone number needs to be verified before registering. Press next to proceed for OTP verification.",
        };
      } else if (code === 136024) {
        // You have already verified ownership of this phone number.
        if (subCode === 2388091) {
          steps = ["register_pin", "health_check", "sync_progress"];
        }
      }
      // If error isn't available then suggest general solutions for troubleshooting
      else {
        steps = [
          "health_check",
          process === "migrate_partner_project"
            ? "migrate_partner_project"
            : "embedded_signup",
        ];
      }
    }

    return { troubleshootSteps: steps, customObject };
  };

  updateWebhookUrl = () => {
    this.setState({ isWebhookLoading: true });
    axios
      .patch(
        projectURL +
          `/project/${this.state.assistant._id}/webhook/${this.state.chatApiWebhook.id}`,
        {
          webhook_url: this.state.chatApiWebhook.webhook_url,
        }
      )
      .then((response) => {
        this.setState(
          {
            isWebhookLoading: false,
            alertType: "success",
            alertMsg: "Successfully Updated webhook",
          },
          this.props.fetchWebhook
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          isWebhookLoading: false,
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, try again",
        });
      });
  };

  copySnack = () => {
    this.setState({
      alertType: "success",
      alertMsg: "API key copied",
    });
  };

  reactivatePlan = async () => {
    this.setState({
      reactivationLoader: true,
    });
    const {
      familyId,
      activePlan: defaultPlan,
      partnerId,
    } = this.state.assistant;
    axios
      .patch(
        partnerURL +
          `/partner/${partnerId}/project/${this.state.assistant._id}/billing/reactivate-project`,
        { familyId, defaultPlan }
      )
      .then((response) => {
        this.setState({
          planStoppedOn: false,
          reactivationLoader: false,
          alertType: "success",
          alertMsg: response.data.message || "plan reactivated Successfully",
        });
        this.props.editAssistants(
          this.state.assistant._id,
          "planStoppedOn",
          false
        );
      })
      .catch((error) => {
        this.setState({
          reactivationLoader: false,
          alertType: "error",
          alertMsg: error?.response?.data?.message || "something went wrong",
        });
      });
  };

  toggleDialog = () => {
    this.props.history.push("/assistants");
  };
  toggleKycDialog = () => {
    this.setState({
      kycDialog: !this.state.kycDialog,
    });
  };
  KycSubmitHandler = async (payload) => {
    const { data, files } = payload;
    const { type, name, size } = files[0];

    // Check file size limit (500 KB)
    const maxSizeInBytes = 500 * 1024;
    if (size > maxSizeInBytes) {
      this.setState({
        reportLoading: false,
        alertType: "error",
        alertMsg: "File size cannot exceed 500kb!",
      });
      console.error("File size exceeds the 500 KB limit.");
      return;
    }

    try {
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("fileName", name);
      formData.append("contentType", type);
      formData.append("fileType", "IMAGE");
      formData.append("gstNumber", data.GstNumber);
      formData.append("consent", "Y");
      formData.append("assistantId", this.state.assistant._id);

      const submissionResponse = await axios.post(
        `${partnerURL}/partner/submit-kyc`,
        formData
      );
      if (submissionResponse.data.validGst) {
        this.setState({
          alertType: "success",
          alertMsg: "Submitted",
        });
        this.toggleKycDialog();
        this.updateAssistant({ bvbpProcess: 1 });
      } else {
        this.setState({
          alertType: "error",
          alertMsg: "Invalid GST",
        });
      }
    } catch (error) {
      this.setState({
        alertType: "error",
        alertMsg: error?.response?.data?.error
          ? error.response.data.error
          : error.message || "Something went wrong",
      });
      console.error("Error during KYC submission:", error);
    }
  };

  getConvoReport = async (startDate, endDate) => {
    const assistant = this.state.assistant;
    const assistantId = assistant?._id;
    const partnerId = this.props.partner._id;
    if (!assistantId) return null;

    this.setState({ reportLoading: true });

    try {
      const response = await axios.post(
        partnerURL +
          `/partner/${partnerId}/project/${assistantId}/project-convo-report`,
        { startDate, endDate },
        { responseType: "stream" }
      );

      const report = response.data?.logs || [];
      const headers = Array.from(
        new Set(report.flatMap((entry) => Object.keys(entry)))
      );
      let csvContent = headers.join(",") + "\n";

      report.forEach((entry) => {
        headers.forEach((header) => {
          const cellContent = entry[header]
            ? `"${entry[header].replace(/\n/g, "\n")}"`
            : "";
          csvContent += cellContent + ",";
        });
        csvContent = csvContent.slice(0, -1);
        csvContent += "\n";
      });

      // Download the file as CSV
      var downloadLink = document.createElement("a");
      const blob = new Blob([csvContent], { type: "text/csv" });
      var url = window.URL.createObjectURL(blob);
      downloadLink.href = url;

      // Format file name
      let start = moment(startDate, "YYYY-MM-DD").isValid()
        ? moment(startDate).format("DD-MM-YYYY")
        : null;
      let end = moment(endDate, "YYYY-MM-DD").isValid()
        ? moment(endDate).format("DD-MM-YYYY")
        : null;

      if (startDate && endDate) {
        downloadLink.download = `Project_Conversation_Report_${start}_to_${end}.csv`;
      } else {
        downloadLink.download = `Project_Conversation_Report.csv`;
      }

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      this.setState({
        reportLoading: false,
        alertType: "success",
        alertMsg: "Successfully Downloaded!",
      });
    } catch (error) {
      this.setState({
        reportLoading: false,
        alertType: "error",
        alertMsg: error?.response?.data?.message || "Error downloading report!",
      });
      console.log(error);
    }
  };

  render() {
    // console.log(this.state.privateApps);
    const { assistant, tab, fbmName, fbmStatus } = this.state;
    const {
      classes,
      planFamilies,
      wccPlans,
      whatsappPaymentPlans,
      partner,
      tenantDetails,
      partnerAgents,
      user,
    } = this.props;
    const {
      type,
      addons,
      centralBalance,
      minAllowedBalance,
      systemUserAccessToken,
    } = partner || {};
    const {
      assistantName,
      isWhatsappVerified,
      currency,
      templateCredit,
      whatsappNumber,
      whatsAppDisplayImage,
      whatsAppAbout,
      whatsAppDisplayName,
      displayNameVerified,
      dailyTemplateLimit,
      qualityRating,
      whatsAppBusinessProfile,
      maxAgents,
      fbManagerVerified,
      partnerId,
      addons: assistantAddons,
      isoCode,
      wabaNumberId,
      wabaAppId,
      wabaProgressStep,
      bvbpProcess,
    } = assistant || {};

    const { address, description, email, vertical, websites } =
      whatsAppBusinessProfile || {};
    const { troubleshootSteps, customObject } = this.embeddedHelper();
    const lowBalance = centralBalance < (minAllowedBalance || 0);

    const hasReadAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "business",
      "read"
    );

    const hasWriteAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "business",
      "write"
    );

    return !this.state.assistant ? (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    ) : (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Grid
          container
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
        >
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              className={classes.dialogTopbar}
            >
              <Header
                businessTitle={`Project: ${assistantName}`}
                onBackPress={this.toggleDialog}
                getConvoReport={this.getConvoReport}
                reportLoading={this.state.reportLoading}
                projectReport={true}
                hasReadAccess={hasReadAccess}
                hasWriteAccess={hasWriteAccess}
                syncWaba={this.syncWaba}
                disableDate={this.state.disableDate}
                planSubmitLoader={this.state.planSubmitLoader}
              />
            </Grid>

            <Box mt={3} />

            <Grid container justifyContent="center">
              <Grid item xs={12} md={11} lg={10}>
                <div className={classes.tableContainer}>
                  <Grid
                    container
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={3}
                      container
                      alignItems="center"
                      direction="column"
                      justifyContent="center"
                    >
                      <Typography variant="h4" color="primary" noWrap>
                        {assistantName}
                      </Typography>
                      <Typography variant="body1" color="textSecondary" noWrap>
                        Project Name
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      container
                      alignItems="center"
                      direction="column"
                      justifyContent="center"
                    >
                      <Typography variant="h4" color="primary" noWrap>
                        <FiberManualRecord
                          style={{
                            color: isWhatsappVerified ? "#08CF65" : "#ff8c00",
                            fontSize: 16,
                            position: "relative",
                            top: 3,
                            right: 3,
                          }}
                        />
                        {isWhatsappVerified ? "LIVE" : "DRAFT"}
                      </Typography>
                      <Typography variant="body1" color="textSecondary" noWrap>
                        Account Status
                      </Typography>
                    </Grid>
                    {["verified", "VERIFIED"].includes(fbManagerVerified) &&
                      ["ready", "verified", "APPROVED", "DEFERRED"].includes(
                        displayNameVerified
                      ) && (
                        <Grid
                          item
                          xs={12}
                          md={3}
                          container
                          alignItems="center"
                          direction="column"
                          justifyContent="center"
                        >
                          <Typography variant="h4" color="primary" noWrap>
                            <FiberManualRecord
                              style={{
                                color: ["Low", "FLAGGED"].includes(
                                  qualityRating
                                )
                                  ? "#ea0037"
                                  : [
                                      "UNFLAGGED",
                                      "Medium",
                                      "RESTRICTED",
                                    ].includes(qualityRating)
                                  ? "#ff8c00"
                                  : "#08CF65",
                                fontSize: 16,
                                position: "relative",
                                top: 3,
                                right: 3,
                              }}
                            />
                            {["Low", "FLAGGED"].includes(qualityRating)
                              ? "LOW"
                              : ["UNFLAGGED", "Medium", "RESTRICTED"].includes(
                                  qualityRating
                                )
                              ? "MEDIUM"
                              : "HIGH"}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            noWrap
                          >
                            Quality Rating
                          </Typography>
                        </Grid>
                      )}
                    <Grid
                      item
                      xs={12}
                      md={3}
                      container
                      alignItems="center"
                      direction="column"
                      justifyContent="center"
                    >
                      <Typography variant="h4" color="primary">
                        {`${!currency || currency === "INR" ? "₹" : "$"} ${
                          !templateCredit
                            ? 0
                            : Number(templateCredit / 100000).toFixed(2)
                        }`}
                      </Typography>
                      <Typography variant="body1" color="textSecondary" noWrap>
                        WhatsApp Credits
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <Grid style={{ marginTop: 16 }}>
                  <Box style={{ marginBottom: "1.7em" }}>
                    <Grid className={classes.roundedBox2}>
                      <Grid xs spacing={1}>
                        <Grid
                          item
                          xs
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="h3"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Business Details
                          </Typography>
                          <Button
                            onClick={this.goToBusiness}
                            style={{ color: "#461486", fontWeight: "bold" }}
                          >
                            Go To Business <ArrowForward color="primary" />
                          </Button>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <div className={classes.roundedBox3}>
                            <Grid
                              container
                              justify={"space-between"}
                              style={{ marginBottom: "0.4em" }}
                            >
                              <Grid item>
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  alignItems="center"
                                >
                                  <Typography variant="body2" gutterBottom>
                                    Company Name
                                  </Typography>
                                  <Box mb="4px" ml={1}>
                                    <Tooltip
                                      enterTouchDelay={0}
                                      style={{ fontSize: "1.2em" }}
                                      placement="top"
                                      title="Name of the business"
                                    >
                                      <Info color="disabled" />
                                    </Tooltip>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  alignItems="center"
                                >
                                  <Typography
                                    variant="h3"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 16,
                                        marginRight: 2,
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {this.state.company}
                                    </span>
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid item>
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  alignItems="center"
                                >
                                  <Typography variant="body2" gutterBottom>
                                    Mobile Number
                                  </Typography>
                                  <Box mb="4px" ml={1}>
                                    <Tooltip
                                      enterTouchDelay={0}
                                      style={{ fontSize: "1.2em" }}
                                      placement="top"
                                      title="Mobile number linked with this business"
                                    >
                                      <Info color="disabled" />
                                    </Tooltip>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  alignItems="center"
                                >
                                  <Typography
                                    variant="h3"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 16,
                                        marginRight: 2,
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {this.state.contact}
                                    </span>
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid item>
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  alignItems="center"
                                >
                                  <Typography variant="body2" gutterBottom>
                                    Admin Email
                                  </Typography>
                                  <Box mb="4px" ml={1}>
                                    <Tooltip
                                      enterTouchDelay={0}
                                      style={{ fontSize: "1.2em" }}
                                      placement="top"
                                      title="Email linked with this business"
                                    >
                                      <Info color="disabled" />
                                    </Tooltip>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  alignItems="center"
                                >
                                  <Typography
                                    variant="h3"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 16,
                                        marginRight: 2,
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {this.state.email}
                                    </span>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Box mt={2} />

                <Box borderBottom="1px solid lightgrey">
                  <Tabs
                    value={tab}
                    onChange={(event, newValue) => {
                      this.setState({
                        tab: newValue,
                      });
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    TabIndicatorProps={{
                      className: classes.tabIndicator,
                    }}
                  >
                    <Tab label="Setup" value="Setup" />
                    <Tab label="Details" value="Details" />
                    <Tab label="Analytics" value="Analytics" />
                    <Tab label="Templates" value="Templates" />
                    <Tab label="Profile" value="Profile" />
                    <Tab label="Billings" value="Billings" />

                    <Tab label="Plan Setup" value="Plan Setup" />

                    {isWhatsappVerified &&
                      Object.keys(addons || {}).length > 0 && (
                        <Tab
                          label="Addons Management"
                          value="Addons Management"
                        />
                      )}
                    {assistantAddons?.WEBHOOKS?.status === "active" &&
                      ["WHITELABEL"].includes(this.props.partner.type) &&
                      !["BASIC_MONTHLY", "BASIC_YEARLY", "NONE"].includes(
                        this.state.assistant.activePlan
                      ) && <Tab label="Webhook" value="Webhook" />}
                    {["WHITELABEL"].includes(this.props.partner.type) &&
                      !["BASIC_MONTHLY", "BASIC_YEARLY", "NONE"].includes(
                        this.state.assistant.activePlan
                      ) &&
                      ["DIAMOND"].includes(partner.planType) && (
                        <Tab label="Project Api" value="Project Api" />
                      )}
                    {addons?.META_ADS?.status === "active" &&
                      addons?.META_ADS?.dashboardKey &&
                      ["WHITELABEL"].includes(this.props.partner.type) &&
                      this.state.assistant.activePlan &&
                      this.state.assistant.activePlan !== "NONE" && (
                        <Tab label="Meta Ad" value="Meta Ad" />
                      )}
                    <Tab label="Defaults Config" value="Defaults Config" />
                  </Tabs>
                </Box>
                <div className={classes.detailContainer}>
                  {tab === "Setup" ? (
                    <Box>
                      <Typography
                        variant="h4"
                        color="primary"
                        noWrap
                        style={{ padding: 20 }}
                      >
                        Onboarding
                      </Typography>

                      <Grid container>
                        <>
                          {type !== "DIRECT" && (
                            <Grid container>
                              <Grid
                                item
                                xs={4}
                                className={classes.cells}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Agents Limit
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className={classes.cells}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {maxAgents ?? "Set agent limit"}
                              </Grid>

                              {hasWriteAccess ? (
                                <Grid
                                  item
                                  xs={4}
                                  className={classes.cells}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <IconButton onClick={this.toggleAgentDialog}>
                                    <Edit />
                                  </IconButton>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Grid>
                          )}
                          {type !== "DIRECT" &&
                            partner?.onboardingMethod ===
                              "WHATSAPP_API_FORM" && (
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  className={classes.cells}
                                >
                                  Whatsapp Application Form Status
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  style={{
                                    display: "flex",
                                    justifyContent:
                                      window.innerWidth >= 768 && "flex-end",
                                  }}
                                  className={classes.cells}
                                >
                                  {assistant?.apiFormDetails
                                    ? "Completed"
                                    : "Incomplete"}
                                </Grid>
                              </Grid>
                            )}
                          {type !== "DIRECT" &&
                            partner?.onboardingMethod ===
                              "WHATSAPP_API_FORM" && (
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  className={classes.cells}
                                >
                                  WhatsApp API Form Details
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  style={{
                                    display: "flex",
                                    justifyContent:
                                      window.innerWidth >= 768 && "flex-end",
                                  }}
                                  className={classes.cells}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.openWabaDialog()}
                                  >
                                    View Details
                                  </Button>
                                </Grid>
                              </Grid>
                            )}

                          {type === "DIRECT" && (
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                className={classes.cells}
                              >
                                Embedded FB Catalog Management
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{
                                  display: "flex",
                                  justifyContent:
                                    window.innerWidth >= 768 && "flex-end",
                                }}
                                className={classes.cells}
                              >
                                {this.state.manageCatalogLinkGenerated ? (
                                  <Box className={classes.urlWrapper}>
                                    <Box className={classes.urlLink}>
                                      {this.state.manageCatalogLinkGenerated}
                                    </Box>
                                    <Box
                                      className={classes.copyLink}
                                      onClick={() => {
                                        this.setState({
                                          alertType: "success",
                                          alertMsg: "URL copied to clipboard",
                                        });
                                        navigator.clipboard.writeText(
                                          this.state.manageCatalogLinkGenerated
                                        );
                                      }}
                                    >
                                      Copy Link
                                    </Box>
                                  </Box>
                                ) : (
                                  <Tooltip
                                    title={
                                      lowBalance ? "Low central balance!" : ""
                                    }
                                  >
                                    <Box>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={this.generateManageCatalogLink}
                                        disabled={lowBalance}
                                      >
                                        Generate URL
                                      </Button>
                                    </Box>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                          )}

                          {dailyTemplateLimit <= 250 &&
                            isWhatsappVerified &&
                            partnerId == "646357594e6a060aa94cb99f" && (
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  className={classes.cells}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  Manage KYC for users
                                  {assistant.bvbpProcess == 0 &&
                                    assistant.kycRemark && (
                                      <Tooltip
                                        placement="top-start"
                                        style={{
                                          marginTop: "5px",
                                        }}
                                        title={`Rejection reason : ${assistant.kycRemark}`}
                                      >
                                        <InfoOutlined
                                          style={{
                                            color: "rgb(255, 71, 71)",
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  style={{
                                    display: "flex",
                                    justifyContent:
                                      window.innerWidth >= 768 && "flex-end",
                                  }}
                                  className={classes.cells}
                                >
                                  <span>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={this.toggleKycDialog}
                                      disabled={bvbpProcess}
                                    >
                                      {!bvbpProcess
                                        ? "Apply"
                                        : bvbpProcess == 1
                                        ? "Applied"
                                        : bvbpProcess == 2
                                        ? "In process"
                                        : bvbpProcess == 3
                                        ? "Approved"
                                        : "Status Unknown"}
                                    </Button>
                                  </span>
                                </Grid>
                              </Grid>
                            )}

                          {isWhatsappVerified &&
                            systemUserAccessToken &&
                            !this.state.isTspWabaId && (
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  className={classes.cells}
                                >
                                  WhatsApp Business API Account Migration URL
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  style={{
                                    display: "flex",
                                    justifyContent:
                                      window.innerWidth >= 768 && "flex-end",
                                  }}
                                  className={classes.cells}
                                >
                                  {this.state.tspMigrationLink ? (
                                    <Box
                                      className={classes.urlWrapper}
                                      style={{ marginRight: "15px" }}
                                    >
                                      <Box className={classes.urlLink}>
                                        {this.state.tspMigrationLink}
                                      </Box>
                                      <Box
                                        className={classes.copyLink}
                                        onClick={() => {
                                          this.setState({
                                            alertType: "success",
                                            alertMsg: "URL copied to clipboard",
                                          });
                                          navigator.clipboard.writeText(
                                            this.state.tspMigrationLink
                                          );
                                        }}
                                      >
                                        Copy Link
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Tooltip
                                      title={
                                        lowBalance ? "Low central balance!" : ""
                                      }
                                    >
                                      <Box style={{ marginRight: "10px" }}>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={
                                            this.generateTSPMigrationLink
                                          }
                                          disabled={lowBalance}
                                        >
                                          Generate TSP URL
                                        </Button>
                                      </Box>
                                    </Tooltip>
                                  )}
                                </Grid>
                              </Grid>
                            )}

                          {!isWhatsappVerified && (
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                className={classes.cells}
                              >
                                WhatsApp Business API Account Migration URL
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{
                                  display: "flex",
                                  justifyContent:
                                    window.innerWidth >= 768 && "flex-end",
                                }}
                                className={classes.cells}
                              >
                                {systemUserAccessToken &&
                                  (this.state.tspMigrationLink ? (
                                    <Box
                                      className={classes.urlWrapper}
                                      style={{ marginRight: "15px" }}
                                    >
                                      <Box className={classes.urlLink}>
                                        {this.state.tspMigrationLink}
                                      </Box>
                                      <Box
                                        className={classes.copyLink}
                                        onClick={() => {
                                          this.setState({
                                            alertType: "success",
                                            alertMsg: "URL copied to clipboard",
                                          });
                                          navigator.clipboard.writeText(
                                            this.state.tspMigrationLink
                                          );
                                        }}
                                      >
                                        Copy Link
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Tooltip
                                      title={
                                        lowBalance ? "Low central balance!" : ""
                                      }
                                    >
                                      <Box style={{ marginRight: "15px" }}>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={
                                            this.generateTSPMigrationLink
                                          }
                                          disabled={lowBalance}
                                        >
                                          Generate TSP URL
                                        </Button>
                                      </Box>
                                    </Tooltip>
                                  ))}

                                {this.state.migrationLinkGenerated ? (
                                  <Box className={classes.urlWrapper}>
                                    <Box className={classes.urlLink}>
                                      {this.state.migrationLinkGenerated}
                                    </Box>
                                    <Box
                                      className={classes.copyLink}
                                      onClick={() => {
                                        this.setState({
                                          alertType: "success",
                                          alertMsg: "URL copied to clipboard",
                                        });
                                        navigator.clipboard.writeText(
                                          this.state.migrationLinkGenerated
                                        );
                                      }}
                                    >
                                      Copy Link
                                    </Box>
                                  </Box>
                                ) : (
                                  <Tooltip
                                    title={
                                      lowBalance ? "Low central balance!" : ""
                                    }
                                  >
                                    <Box>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={this.generateWabaMigrationLink}
                                        disabled={lowBalance}
                                      >
                                        Generate URL
                                      </Button>
                                    </Box>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                          )}
                          {!isWhatsappVerified && (
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                className={classes.cells}
                              >
                                Embedded SignUp URL
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{
                                  display: "flex",
                                  justifyContent:
                                    window.innerWidth >= 768 && "flex-end",
                                }}
                                className={classes.cells}
                              >
                                {this.state.linkGenerated ? (
                                  <Box className={classes.urlWrapper}>
                                    <Box className={classes.urlLink}>
                                      {this.state.linkGenerated}
                                    </Box>
                                    <Box
                                      className={classes.copyLink}
                                      onClick={() => {
                                        this.setState({
                                          alertType: "success",
                                          alertMsg: "URL copied to clipboard",
                                        });
                                        navigator.clipboard.writeText(
                                          this.state.linkGenerated
                                        );
                                      }}
                                    >
                                      Copy Link
                                    </Box>
                                  </Box>
                                ) : (
                                  <Tooltip
                                    title={
                                      lowBalance ? "Low central balance!" : ""
                                    }
                                  >
                                    <Box>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={this.generateWabaLink}
                                        disabled={lowBalance}
                                      >
                                        Generate URL
                                      </Button>
                                    </Box>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                          )}
                          {/* {assistant && (
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                className={classes.cells}
                              >
                                {assistant.isWhatsappVerified
                                  ? "Sync Waba Status"
                                  : "Sync Waba Progress"}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                style={{
                                  display: "flex",
                                  justifyContent:
                                    window.innerWidth >= 768 && "flex-end",
                                }}
                                className={classes.cells}
                              >
                                <Tooltip
                                  title={
                                    Number(this.state.disableDate) -
                                      Date.now() >=
                                      0 || this.state.planSubmitLoader
                                      ? "Retry again a in few mins"
                                      : "Sync WABA"
                                  }
                                >
                                  <span>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={this.syncWaba}
                                      disabled={
                                        Number(this.state.disableDate) -
                                          Date.now() >=
                                          0 || this.state.planSubmitLoader
                                      }
                                      endIcon={
                                        this.state.planSubmitLoader ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )
                                      }
                                    >
                                      Sync
                                    </Button>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          )} */}
                        </>
                      </Grid>

                      {troubleshootSteps.length > 0 && hasWriteAccess && (
                        <StepBasedSolutions
                          assistant={assistant}
                          partnerId={partnerId}
                          EmbeddedSignup={this.EmbeddedSignup}
                          EmbeddedMigration={this.EmbeddedMigration}
                          SyncProgress={this.SyncProgress}
                          setSnackbar={this.setSnackbar}
                          steps={troubleshootSteps}
                          customMsgObject={customObject}
                          setMetaError={this.setMetaError}
                          wabaIsLive={
                            !!(wabaProgressStep === 9 && isWhatsappVerified)
                          }
                        />
                      )}
                    </Box>
                  ) : tab === "Details" ? (
                    <Box>
                      <Box padding={4}>
                        <Typography variant="h4" color="primary" noWrap>
                          WhatsApp Channel
                        </Typography>
                        <Box mt={2} />
                        <Grid container>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            Phone Number
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            {whatsappNumber}
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            <Typography variant="body1" color="primary" noWrap>
                              {/* DONE */}
                              {/* <FiberManualRecord
                                style={{
                                  color: "#08CF65",
                                  fontSize: 12,
                                  position: "relative",
                                  top: 2,
                                  left: 3,
                                }}
                              /> */}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={4} className={classes.cells}>
                            WhatsApp Display Name
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            {whatsAppDisplayName}
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            <Typography
                              variant="body1"
                              color="primary"
                              noWrap
                              style={{ textTransform: "uppercase" }}
                            >
                              {displayNameVerified}
                              {/* <FiberManualRecord
                                style={{
                                  color: ["ready", "verified"].includes(
                                    displayNameVerified
                                  )
                                    ? "#08CF65"
                                    : "#ff8c00",
                                  fontSize: 12,
                                  position: "relative",
                                  top: 2,
                                  left: 3,
                                }}
                              /> */}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={4} className={classes.cells}>
                            Messaging Limit
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            {dailyTemplateLimit}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.cells}
                          ></Grid>

                          <Grid item xs={12} md={4} className={classes.cells}>
                            Quality Rating
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            {qualityRating}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.cells}
                          ></Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            WABA Number ID
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            {wabaNumberId}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.cells}
                          ></Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            WABA App ID
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            {wabaAppId}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.cells}
                          ></Grid>

                          <Grid item xs={12} md={4} className={classes.cells}>
                            FBM Name
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            {fbmName}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.cells}
                          ></Grid>

                          <Grid item xs={12} md={4} className={classes.cells}>
                            FBM Verification Status
                          </Grid>
                          <Grid item xs={12} md={4} className={classes.cells}>
                            {fbmStatus}
                          </Grid>

                          {/* <Grid
                            item
                            xs={12}
                            className={classes.cells}
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            Embedded SignUp URL
                            {this.state.linkGenerated ? (
                              <Box className={classes.urlWrapper}>
                                <Box className={classes.urlLink}>
                                  {this.state.linkGenerated}
                                </Box>
                                <Box
                                  className={classes.copyLink}
                                  onClick={() => {
                                    this.setState({
                                      alertType: "success",
                                      alertMsg: "URL copied to clipboard",
                                    });
                                    navigator.clipboard.writeText(
                                      this.state.linkGenerated
                                    );
                                  }}
                                >
                                  Copy Link
                                </Box>
                              </Box>
                            ) : (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={this.generateWabaLink}
                              >
                                Generate URL
                              </Button>
                            )}
                          </Grid> */}

                          {/* <Grid
                            item
                            xs={12}
                            className={classes.cells}
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            Sync Waba Progress
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={this.syncWaba}
                            >
                              Sync
                            </Button>
                          </Grid> */}
                        </Grid>
                      </Box>
                    </Box>
                  ) : tab === "Analytics" ? (
                    <Box>
                      <WccAnalytics
                        projectId={this.props.match.params.projectId}
                        partnerId={partner._id}
                        currency={currency}
                      />
                    </Box>
                  ) : tab === "Templates" ? (
                    <Box>
                      <TemplateRoute
                        isWhatsappVerified={isWhatsappVerified ? true : false}
                        hasWriteAccess={hasWriteAccess}
                      />
                    </Box>
                  ) : tab === "Profile" ? (
                    <Box>
                      {isWhatsappVerified ? (
                        <Box padding={4}>
                          <Typography variant="h4" color="primary" noWrap>
                            WhatsApp Business Profile
                          </Typography>
                          <Box mt={2} />
                          <Grid container>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              Profile Picture
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {this.state.editProfilePicture ? (
                                type === "DIRECT" ? (
                                  <TextField
                                    name="newProfilePicture"
                                    placeholder="Your image url here"
                                    className={classes.textField}
                                    onChange={this.handleInput}
                                    onKeyPress={this.handleEnter}
                                  />
                                ) : (
                                  <input
                                    id="file_uploader"
                                    type="file"
                                    accept="image/jpeg, image/png"
                                    hidden
                                    onChange={this.uploadFile}
                                  />
                                )
                              ) : this.state.newProfilePicture ||
                                whatsAppDisplayImage ? (
                                <img
                                  src={
                                    this.state.newProfilePicture ||
                                    whatsAppDisplayImage
                                  }
                                  alt="pp"
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                  }}
                                />
                              ) : (
                                <Person style={{ fontSize: 26 }} />
                              )}
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.cells}>
                              {hasWriteAccess ? (
                                this.state.editProfilePicture ? (
                                  <>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="contained"
                                      onClick={() => {
                                        if (type === "DIRECT")
                                          this.updateProfileViaDirectAPI(
                                            "whatsAppDisplayImage",
                                            this.state.newProfilePicture,
                                            "editProfilePicture"
                                          );
                                        else this.onEdit("newProfilePicture");
                                      }}
                                      style={{ marginRight: 16 }}
                                      disabled={this.state.loadProfilePicture}
                                      endIcon={
                                        this.state.loadProfilePicture ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )
                                      }
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="outlined"
                                      onClick={() => {
                                        this.editToggle("editProfilePicture");
                                        this.setState({
                                          wasImageChanged: false,
                                          selectedFile: {},
                                        });
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={this.openFileUploader}
                                  >
                                    Change Profile Picture
                                  </Button>
                                )
                              ) : null}
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.cells}>
                              About text
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {this.state.editAboutText ? (
                                <TextField
                                  name="newAboutText"
                                  placeholder="Your business description here"
                                  className={classes.textField}
                                  onChange={this.handleInput}
                                  onKeyPress={this.handleEnter}
                                />
                              ) : (
                                this.state.newAboutText || whatsAppAbout
                              )}
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {hasWriteAccess ? (
                                this.state.editAboutText ? (
                                  <>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="contained"
                                      onClick={() => {
                                        if (type === "DIRECT")
                                          this.updateProfileViaDirectAPI(
                                            "whatsAppAbout",
                                            this.state.newAboutText,
                                            "editAboutText"
                                          );
                                        else this.onEdit("newAboutText");
                                      }}
                                      style={{ marginRight: 16 }}
                                      disabled={this.state.loadAboutText}
                                      endIcon={
                                        this.state.loadAboutText ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )
                                      }
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="outlined"
                                      onClick={() => {
                                        this.editToggle("editAboutText");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      this.editToggle("editAboutText");
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                )
                              ) : null}
                            </Grid>
                          </Grid>
                          <Box mt={8} />
                          <Typography variant="h4" color="primary" noWrap>
                            Business Profile
                          </Typography>

                          <Grid container>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              Address
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {this.state.editAddress ? (
                                <TextField
                                  name="newAddress"
                                  placeholder="Your address here"
                                  className={classes.textField}
                                  onChange={this.handleInput}
                                  onKeyPress={this.handleEnter}
                                />
                              ) : this.state.newAddress || address ? (
                                this.state.newAddress || address
                              ) : (
                                <i>N/A</i>
                              )}
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {hasWriteAccess &&
                                (this.state.editAddress ? (
                                  <>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="contained"
                                      onClick={() => {
                                        if (type === "DIRECT")
                                          this.updateProfileViaDirectAPI(
                                            "address",
                                            this.state.newAddress,
                                            "editAddress"
                                          );
                                        else this.onEdit("newAddress");
                                      }}
                                      disabled={this.state.loadAddress}
                                      style={{ marginRight: 16 }}
                                      endIcon={
                                        this.state.loadAddress ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )
                                      }
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="outlined"
                                      onClick={() => {
                                        this.editToggle("editAddress");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      this.editToggle("editAddress");
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                ))}
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              Description
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {this.state.editDescription ? (
                                <TextField
                                  name="newDescription"
                                  placeholder="Your description here"
                                  className={classes.descriptionTextField}
                                  onChange={this.handleInput}
                                  onKeyPress={this.handleEnter}
                                  multiline
                                  minRows={2}
                                  maxRows={10}
                                />
                              ) : this.state.newDescription || description ? (
                                this.state.newDescription || description
                              ) : (
                                <i>N/A</i>
                              )}
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {hasWriteAccess &&
                                (this.state.editDescription ? (
                                  <>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="contained"
                                      onClick={() => {
                                        if (type === "DIRECT")
                                          this.updateProfileViaDirectAPI(
                                            "description",
                                            this.state.newDescription,
                                            "editDescription"
                                          );
                                        else this.onEdit("newDescription");
                                      }}
                                      disabled={this.state.loadDescription}
                                      style={{ marginRight: 16 }}
                                      endIcon={
                                        this.state.loadDescription ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )
                                      }
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="outlined"
                                      onClick={() => {
                                        this.editToggle("editDescription");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      this.editToggle("editDescription");
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                ))}
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              Email
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {this.state.editEmail ? (
                                <TextField
                                  name="newEmail"
                                  placeholder="Your email here"
                                  className={classes.textField}
                                  onChange={this.handleInput}
                                  onKeyPress={this.handleEnter}
                                />
                              ) : this.state.newEmail || email ? (
                                this.state.newEmail || email
                              ) : (
                                <i>N/A</i>
                              )}
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {hasWriteAccess &&
                                (this.state.editEmail ? (
                                  <>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="contained"
                                      onClick={() => {
                                        if (type === "DIRECT")
                                          this.updateProfileViaDirectAPI(
                                            "email",
                                            this.state.newEmail,
                                            "editEmail"
                                          );
                                        else this.onEdit("newEmail");
                                      }}
                                      disabled={this.state.loadEmail}
                                      style={{ marginRight: 16 }}
                                      endIcon={
                                        this.state.loadEmail ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )
                                      }
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="outlined"
                                      onClick={() => {
                                        this.editToggle("editEmail");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      this.editToggle("editEmail");
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                ))}
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.cells}>
                              Website
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {this.state.editWebsite ? (
                                <TextField
                                  name="newWebsite"
                                  placeholder="Your website here"
                                  className={classes.textField}
                                  onChange={this.handleInput}
                                  onKeyPress={this.handleEnter}
                                />
                              ) : this.state.newWebsite || websites ? (
                                this.state.newWebsite || websites.join(", ")
                              ) : (
                                <i>N/A</i>
                              )}
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {hasWriteAccess &&
                                (this.state.editWebsite ? (
                                  <>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="contained"
                                      onClick={() => {
                                        if (type === "DIRECT")
                                          this.updateProfileViaDirectAPI(
                                            "websites",
                                            this.state.newWebsite
                                              .split(",")
                                              .map(function (item) {
                                                return item.trim();
                                              }),
                                            "editWebsite"
                                          );
                                        else this.onEdit("newWebsite");
                                      }}
                                      style={{ marginRight: 16 }}
                                      disabled={this.state.loadWebsite}
                                      endIcon={
                                        this.state.loadWebsite ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )
                                      }
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="outlined"
                                      onClick={() => {
                                        this.editToggle("editWebsite");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      this.editToggle("editWebsite");
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                ))}
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.cells}>
                              Business vertical
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {this.state.editBusinessVertical ? (
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={
                                    this.state.newBusinessVertical || vertical
                                  }
                                  onChange={(event) => {
                                    this.setState({
                                      newBusinessVertical: event.target.value,
                                    });
                                  }}
                                >
                                  {verticals.map((e) => (
                                    <MenuItem value={e}>{e}</MenuItem>
                                  ))}
                                </Select>
                              ) : this.state.newBusinessVertical || vertical ? (
                                this.state.newBusinessVertical || vertical
                              ) : (
                                <i>N/A</i>
                              )}
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {hasWriteAccess &&
                                (this.state.editBusinessVertical ? (
                                  <>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="contained"
                                      onClick={() => {
                                        if (type === "DIRECT")
                                          this.updateProfileViaDirectAPI(
                                            "vertical",
                                            this.state.newBusinessVertical,
                                            "editBusinessVertical"
                                          );
                                        else this.onEdit("newBusinessVertical");
                                      }}
                                      style={{ marginRight: 16 }}
                                      disabled={this.state.loadBusinessVertical}
                                      endIcon={
                                        this.state.loadBusinessVertical ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )
                                      }
                                    >
                                      Save
                                    </Button>
                                    <Button
                                      color="primary"
                                      size="medium"
                                      variant="outlined"
                                      onClick={() => {
                                        this.editToggle("editBusinessVertical");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <IconButton
                                    onClick={() => {
                                      this.editToggle("editBusinessVertical");
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                ))}
                            </Grid>
                          </Grid>
                        </Box>
                      ) : (
                        <>
                          <Typography
                            variant="h4"
                            color="primary"
                            noWrap
                            style={{ padding: 20 }}
                          >
                            WhatsApp Business Profile
                          </Typography>
                          <Grid container>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              Profile Picture
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              {whatsAppDisplayImage ? (
                                <img
                                  src={whatsAppDisplayImage}
                                  alt="pp"
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                  }}
                                />
                              ) : (
                                <Person style={{ fontSize: 26 }} />
                              )}
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              <input
                                id="file_uploader"
                                type="file"
                                accept="image/jpeg, image/png"
                                hidden
                              />

                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={true}
                              >
                                Change Profile Picture
                              </Button>
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.cells}>
                              About text
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              <></>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.cells}>
                              <IconButton disabled={true}>
                                <Edit />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Box mt={8} />

                          <Box mt={4} textAlign="center">
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              style={{ padding: 20 }}
                            >
                              Waba is not live yet!
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  ) : tab === "Billings" ? (
                    <Box style={{ overflow: "hidden" }}>
                      <BillingPage
                        assistants={isWhatsappVerified ? true : false}
                      />
                    </Box>
                  ) : tab === "Plan Setup" ? (
                    <PlanSetup
                      classes={classes}
                      partner={partner}
                      planFamilies={planFamilies}
                      wccPlans={wccPlans}
                      whatsappPaymentPlans={whatsappPaymentPlans}
                      assistant={assistant}
                      setSnackbar={this.setSnackbar}
                      modifyAssistant={this.props.editAssistants}
                      setAssistant={(assistant) => this.setState({ assistant })}
                      history={this.props.history}
                      partnerAgents={partnerAgents}
                      user={user}
                      fecthAssistant={this.fecthAssistant}
                    />
                  ) : tab === "Addons Management" ? (
                    <AddonsManagement
                      classes={classes}
                      partner={partner}
                      assistantAddons={assistantAddons}
                      assistantId={assistant._id}
                      setSnackbar={this.setSnackbar}
                      tenantDetails={tenantDetails}
                      hasWriteAccess={hasWriteAccess}
                    />
                  ) : tab === "Webhook" ? (
                    <Box>
                      {isWhatsappVerified ? (
                        <Box padding={4}>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography variant="h4" color="primary" noWrap>
                                <FiberManualRecord
                                  style={{
                                    color: isWhatsappVerified
                                      ? "#08CF65"
                                      : "#ff8c00",
                                    fontSize: 16,
                                    position: "relative",
                                    top: 3,
                                    right: 3,
                                  }}
                                />
                                webhooks
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Tooltip
                                title={
                                  this.state.webhookResult.length >= 5
                                    ? "Maximux(5) Webhook Is Created"
                                    : ""
                                }
                              >
                                <span>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<Add />}
                                    style={{ marginRight: 8, height: 42 }}
                                    onClick={this.webhookDialog}
                                    disabled={
                                      this.state.webhookResult.length >= 5
                                        ? true
                                        : false
                                    }
                                  >
                                    Create New Webhook
                                  </Button>
                                </span>
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <>
                            <Box mt={2} />
                            <WebhookMappingsTable
                              assistantId={this.props.match.params.projectId}
                              isLoading={this.state.isWebhookLoading}
                              webhooks={this.state.webhookResult}
                              fetchWebhook={this.fetchWebhook}
                            />
                          </>
                        </Box>
                      ) : (
                        <Box textAlign="center">
                          <Grid
                            container
                            justifyContent="space-between"
                            style={{ padding: 20, backgroundColor: "white" }}
                          >
                            <Grid item>
                              <Typography variant="h4" color="primary" noWrap>
                                <FiberManualRecord
                                  style={{
                                    color: isWhatsappVerified
                                      ? "#08CF65"
                                      : "#ff8c00",
                                    fontSize: 16,
                                    position: "relative",
                                    top: 3,
                                    right: 3,
                                  }}
                                />
                                webhooks
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Button
                                color="primary"
                                variant="contained"
                                startIcon={<Add />}
                                style={{ marginRight: 8, height: 42 }}
                                onClick={this.webhookDialog}
                                disabled={!isWhatsappVerified ? true : false}
                              >
                                Create New Webhook
                              </Button>
                            </Grid>
                          </Grid>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            style={{ marginTop: 20 }}
                          >
                            Waba is not live yet!
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ) : tab === "Project Api" ? (
                    <Box textAlign="center" style={{ paddingTop: 12 }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        style={{ padding: 20 }}
                      >
                        <Grid item>
                          <Typography variant="h4" color="primary" noWrap>
                            <FiberManualRecord
                              style={{
                                color: isWhatsappVerified
                                  ? "#08CF65"
                                  : "#ff8c00",
                                fontSize: 16,
                                position: "relative",
                                top: 3,
                                right: 3,
                              }}
                            />
                            Project API
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          display: "flex",
                          paddingLeft: 20,
                          paddingRight: 34,
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          item
                          md={3}
                          xs={12}
                          style={{ display: "flex", marginTop: 24 }}
                        >
                          <Typography
                            variant="body1"
                            gutterBottom
                            // style={{ fontWeight: "bold" }}
                          >
                            Project API Password
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={9}
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            width: "100%",
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                              The password shown here will be lost after being
                              copied.
                            </Typography>
                          </Grid>
                          <Grid
                            xs={12}
                            item
                            style={{
                              display: "flex",
                              width: "100%",
                              gap: "8px",
                            }}
                          >
                            <Grid item md={9} xs={12}>
                              <TextField
                                className={classes.textFieldDisabled}
                                disabled={true}
                                type="text"
                                onCopy={() => {
                                  navigator.clipboard.writeText(
                                    this.state.password
                                  );
                                  this.setState({ password: "" });
                                }}
                                placeholder="Click Reset to get a new password"
                                fullWidth
                                value={this.state.password}
                                InputProps={{
                                  endAdornment: (
                                    <Tooltip title="Click to copy Password">
                                      <span>
                                        <IconButton
                                          size="small"
                                          disabled={!this.state.password}
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              this.state.password
                                            );
                                            this.setState({ password: "" });
                                            this.copySnack();
                                          }}
                                        >
                                          <FileCopyIcon fontSize="small" />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              style={{ display: "flex" }}
                            >
                              {hasWriteAccess && (
                                <Button
                                  style={{ width: "100%" }}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    if (
                                      Object.keys(this.state.privateApps)
                                        .length > 0
                                    ) {
                                      this.updatePrivateApp();
                                    } else {
                                      this.CreatePrivateApp();
                                    }
                                  }}
                                  endIcon={
                                    this.state.isLoading ? (
                                      <CircularProgress
                                        style={{ color: "white" }}
                                        size={20}
                                      />
                                    ) : (
                                      ""
                                    )
                                  }
                                >
                                  {Object.keys(this.state.privateApps).length >
                                  0
                                    ? "Reset"
                                    : "Create"}
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        style={{ padding: 20, marginTop: 24 }}
                      >
                        <Grid item>
                          <Typography variant="h4" color="primary" noWrap>
                            <FiberManualRecord
                              style={{
                                color: isWhatsappVerified
                                  ? "#08CF65"
                                  : "#ff8c00",
                                fontSize: 16,
                                position: "relative",
                                top: 3,
                                right: 3,
                              }}
                            />
                            Chat API URL
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          display: "flex",
                          paddingLeft: 20,
                          paddingRight: 34,
                          alignItems: "center",
                        }}
                      >
                        <Grid item md={3} xs={12} style={{ display: "flex" }}>
                          <Typography
                            variant="body1"
                            gutterBottom
                            // style={{ fontWeight: "bold" }}
                          >
                            WebHook URL
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={9}
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            width: "100%",
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                              Endpoint URL to receive webhook events
                            </Typography>
                          </Grid>
                          <Grid
                            xs={12}
                            item
                            style={{
                              display: "flex",
                              width: "100%",
                              gap: "8px",
                            }}
                          >
                            <Grid item md={9} xs={12}>
                              <TextField
                                className={classes.textFieldDisabled}
                                type="text"
                                placeholder="Click Reset to get a new password"
                                fullWidth
                                value={this.state.chatApiWebhook.webhook_url}
                                onChange={(event) => {
                                  if (this.state.chatApiWebhook.webhook_url) {
                                    this.setState({
                                      chatApiWebhook: {
                                        ...this.state.chatApiWebhook,
                                        webhook_url: event.target.value,
                                      },
                                    });
                                  } else {
                                    this.setState({
                                      webhookUrl: event.target.value,
                                    });
                                  }
                                }}
                                // InputProps={{
                                //   endAdornment: (
                                //     <Tooltip title="Click to copy Password">
                                //       <span>
                                //         <IconButton
                                //           size="small"
                                //           disabled={!this.state.password}
                                //           onClick={() => {
                                //             navigator.clipboard.writeText(
                                //               this.state.password
                                //             );
                                //             this.copySnack();
                                //           }}
                                //         >
                                //           <FileCopyIcon fontSize="small" />
                                //         </IconButton>
                                //       </span>
                                //     </Tooltip>
                                //   ),
                                // }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              style={{ display: "flex" }}
                            >
                              {hasWriteAccess && (
                                <Button
                                  style={{ width: "100%" }}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    if (
                                      Object.keys(this.state.chatApiWebhook)
                                        .length > 0
                                    ) {
                                      this.updateWebhookUrl();
                                    } else {
                                      this.createWebhook();
                                    }
                                  }}
                                  endIcon={
                                    this.state.isWebhookLoading ? (
                                      <CircularProgress
                                        style={{ color: "white" }}
                                        size={20}
                                      />
                                    ) : (
                                      ""
                                    )
                                  }
                                >
                                  {Object.keys(this.state.chatApiWebhook)
                                    .length > 0
                                    ? "Update"
                                    : "Create"}
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        style={{ padding: 20, marginTop: 24 }}
                      >
                        <Grid item>
                          <Typography variant="h4" color="primary" noWrap>
                            <FiberManualRecord
                              style={{
                                color: isWhatsappVerified
                                  ? "#08CF65"
                                  : "#ff8c00",
                                fontSize: 16,
                                position: "relative",
                                top: 3,
                                right: 3,
                              }}
                            />
                            Shared Secret
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          display: "flex",
                          paddingLeft: 20,
                          paddingRight: 34,
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          item
                          md={3}
                          xs={12}
                          style={{ display: "flex", marginTop: 24 }}
                        >
                          <Typography
                            variant="body1"
                            gutterBottom
                            // style={{ fontWeight: "bold" }}
                          >
                            Shared Secret
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={9}
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            width: "100%",
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                              Shared secret to validate the received webhook
                              event
                            </Typography>
                          </Grid>
                          <Grid
                            xs={12}
                            item
                            style={{
                              display: "flex",
                              width: "100%",
                              gap: "8px",
                            }}
                          >
                            <Grid item md={9} xs={12}>
                              <TextField
                                className={classes.textFieldDisabled}
                                disabled={true}
                                type="text"
                                placeholder="Shared secret to verify the webhook data"
                                fullWidth
                                onCopy={() => {
                                  navigator.clipboard.writeText(
                                    this.state.SharedSecret
                                  );
                                  this.setState({
                                    SharedSecret: "",
                                  });
                                }}
                                value={this.state.SharedSecret}
                                InputProps={{
                                  endAdornment: (
                                    <Tooltip title="Click to copy shared secret">
                                      <span>
                                        <IconButton
                                          size="small"
                                          disabled={!this.state.SharedSecret}
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              this.state.SharedSecret
                                            );
                                            this.setState({
                                              SharedSecret: "",
                                            });
                                            this.copySnack();
                                          }}
                                        >
                                          <FileCopyIcon fontSize="small" />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              style={{ display: "flex" }}
                            >
                              {hasWriteAccess && (
                                <Button
                                  style={{ width: "100%" }}
                                  variant="contained"
                                  color="primary"
                                  disabled={
                                    !this.state.chatApiWebhook.shared_secret
                                  }
                                  onClick={() => {
                                    if (
                                      this.state.chatApiWebhook.shared_secret
                                    ) {
                                      this.setState({
                                        SharedSecret:
                                          this.state.chatApiWebhook
                                            .shared_secret,
                                      });
                                    } else {
                                      this.setState({
                                        alertType: "error",
                                        alertMsg: "subscribe to webhook first",
                                      });
                                    }
                                  }}
                                >
                                  Generate
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : tab === "Meta Ad" ? (
                    <Box style={{ overflow: "hidden" }}>
                      <SetupMetaAds
                        assistantDetails={this.state.assistant}
                        updateAssistantStore={this.updateAssistant}
                      />
                    </Box>
                  ) : (
                    tab === "Defaults Config" && (
                      <Box style={{ overflow: "hidden" }}>
                        <DefaultsPage
                          assistantId={assistant._id}
                          isoCode={isoCode}
                          whatsAppAbout={whatsAppAbout}
                          businessWriteAccess={hasWriteAccess}
                        />
                      </Box>
                    )
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {this.state.showAgentDialog && (
          <AgentLimitDialog
            openDialog={this.state.showAgentDialog}
            closeDialog={this.toggleAgentDialog}
            classes={classes}
            currentValue={maxAgents}
            changeValueFn={this.updateAgentLimit}
          />
        )}
        <Dialog
          open={this.state.openWebhookDialog}
          maxWidth="sm"
          onClose={this.webhookDialog}
          aria-labelledby="form-dialog-title"
          className={classes.plandialogContainer}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                item
                container
                justifyContent="flex-end"
                xs={12}
                // style={{ height: 30, marginBottom: 10 }}
              >
                <IconButton onClick={this.webhookDialog}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid
                container
                style={{
                  alignItems: "center",
                  marginTop: "15px",
                  paddingLeft: 12,
                  paddingRight: 36,
                }}
              >
                <Grid item xs={12} md={2} style={{ marginBottom: "20px" }}>
                  <Typography>Topics</Typography>
                </Grid>
                <Grid item xs={12} md={10} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    multiple
                    options={allTopics}
                    // defaultValue={}
                    filterSelectedOptions
                    onChange={(e, value, reason) => {
                      if (reason === "select-option") {
                        this.setState({ selectedTopic: value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="topics"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2} style={{ marginBottom: "20px" }}>
                  <Typography>WebHook Url</Typography>
                </Grid>
                <Grid item xs={12} md={10} style={{ marginBottom: "20px" }}>
                  <TextField
                    name="webhook url"
                    placeholder="webhook url"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      this.setState({ selectedWebhookurl: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.webhookSubmit}
                style={{ margin: 16 }}
                disabled={this.state.webhookSubmitLoader}
                endIcon={
                  this.state.webhookSubmitLoader ? (
                    <CircularProgress size={20} />
                  ) : (
                    ""
                  )
                }
              >
                Submit
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openPrivateAppDialog}
          maxWidth="sm"
          onClose={this.privateAppDialog}
          aria-labelledby="form-dialog-title"
          className={classes.plandialogContainer}
        >
          <DialogContent>
            <>
              <Grid
                container
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 24,
                }}
              >
                <Typography variant="h3">Generate Project API Key</Typography>
                <IconButton
                  onClick={this.privateAppDialog}
                  style={{ marginRight: -8 }}
                >
                  <Close />
                </IconButton>
              </Grid>
              <Grid style={{ display: "flex" }} xs={12}>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ marginRight: 4 }}
                >
                  Click generate to create Api key and on copy button to copy it
                </Typography>
              </Grid>
              <Grid container spacing={2} alignItems="center" xs={12}>
                <Grid item xs={8}>
                  <TextField
                    className={classes.textFieldDisabled}
                    disabled={true}
                    type="text"
                    placeholder="Click on copy button to copy password"
                    fullWidth
                    value={this.state.appPassword}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Click to copy Password">
                          <span>
                            <IconButton
                              size="small"
                              disabled={!this.state.appPassword}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  this.state.appPassword
                                );
                                this.copySnack();
                              }}
                            >
                              <FileCopyIcon fontSize="small" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.CreatePrivateApp}
                  >
                    Generate
                  </Button>
                </Grid>
              </Grid>
            </>
          </DialogContent>
          <DialogActions>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.privateAppDialog}
                style={{ margin: 16 }}
                disabled={this.state.isLoading}
                endIcon={
                  this.state.webhookSubmitLoader ? (
                    <CircularProgress size={20} />
                  ) : (
                    ""
                  )
                }
              >
                Close
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openWccDialog}
          maxWidth="xs"
          onClose={this.wccDialog}
          aria-labelledby="form-dialog-title"
          className={classes.plandialogContainer}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                item
                container
                justifyContent="flex-end"
                xs={12}
                style={{ height: 30, marginBottom: 10 }}
              >
                <IconButton onClick={this.wccDialog}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid
                container
                style={{ alignItems: "center", marginTop: "15px" }}
              >
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>action</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    disableClearable
                    autoHighlight
                    options={["ADD", "SUBTRACT"]}
                    onChange={(e, value, reason) => {
                      if (reason === "select-option") {
                        this.setState({ wccAction: value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select an action"
                        name="action"
                      />
                    )}
                    style={{ width: "calc(100%)", paddingTop: "0px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>Amount</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <TextField
                    name="amount"
                    placeholder=" Your amount here"
                    style={{ width: "100%" }}
                    InputProps={{
                      startAdornment:
                        this.props.partner?.currency === "USD" ? "$" : "₹",
                    }}
                    onChange={(e) => {
                      const value = Number(e.target.value ?? 0);
                      if (value > 0)
                        this.setState({
                          wccAmount: value * 100000,
                        });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.wccSubmit}
                style={{ margin: 16 }}
                disabled={this.state.wccSubmitLoader}
                endIcon={
                  this.state.wccSubmitLoader ? (
                    <CircularProgress size={20} />
                  ) : (
                    ""
                  )
                }
              >
                Submit
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openAdCreditDialog}
          maxWidth="xs"
          onClose={this.adCreditDialog}
          aria-labelledby="form-dialog-title"
          className={classes.plandialogContainer}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                item
                container
                justifyContent="flex-end"
                xs={12}
                style={{ height: 30, marginBottom: 10 }}
              >
                <IconButton onClick={this.adCreditDialog}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid
                container
                style={{ alignItems: "center", marginTop: "15px" }}
              >
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>action</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    disableClearable
                    autoHighlight
                    options={["ADD", "SUBTRACT"]}
                    onChange={(e, value, reason) => {
                      if (reason === "select-option") {
                        this.setState({ adCreditAction: value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select an action"
                        name="action"
                      />
                    )}
                    style={{ width: "calc(100%)", paddingTop: "0px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>Amount</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <TextField
                    name="amount"
                    placeholder=" Your amount here"
                    style={{ width: "100%" }}
                    InputProps={{
                      startAdornment:
                        this.props.partner?.currency === "USD" ? "$" : "₹",
                    }}
                    onChange={(e) => {
                      const value = Number(e.target.value ?? 0);
                      if (value > 0)
                        this.setState({
                          adCreditAmount: value * 100000,
                        });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.adCreditSubmit}
                style={{ margin: 16 }}
                disabled={this.state.adCreditSubmitLoader}
                endIcon={
                  this.state.adCreditSubmitLoader ? (
                    <CircularProgress size={20} />
                  ) : (
                    ""
                  )
                }
              >
                Submit
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openPwcDialog}
          maxWidth="xs"
          onClose={this.pwcDialog}
          aria-labelledby="form-dialog-title"
          className={classes.plandialogContainer}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                item
                container
                justifyContent="flex-end"
                xs={12}
                style={{ height: 30, marginBottom: 10 }}
              >
                <IconButton onClick={this.pwcDialog}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid
                container
                style={{ alignItems: "center", marginTop: "15px" }}
              >
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>Action</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    disableClearable
                    autoHighlight
                    options={["ADD", "SUBTRACT"]}
                    onChange={(e, value, reason) => {
                      if (reason === "select-option") {
                        this.setState({ pwcAction: value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select an action"
                        name="action"
                      />
                    )}
                    style={{ width: "calc(100%)", paddingTop: "0px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>Amount</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <TextField
                    name="amount"
                    placeholder=" Your amount here"
                    type="number"
                    style={{ width: "100%" }}
                    InputProps={{
                      startAdornment:
                        this.props.partner?.currency === "USD" ? "$" : "₹",
                    }}
                    onChange={(e) => {
                      const value = Number(e.target.value ?? 0);
                      if (value > 0)
                        this.setState({
                          pwcAmount: value * 100000,
                        });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.pwcSubmit}
                style={{ margin: 16 }}
                disabled={this.state.pwcSubmitLoader}
                endIcon={
                  this.state.pwcSubmitLoader ? (
                    <CircularProgress size={20} />
                  ) : (
                    ""
                  )
                }
              >
                Submit
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.handleClickDialog}
          closeDialog={this.closeHandleDialog}
          maxWidth={"xs"}
        >
          <Grid
            item
            container
            justifyContent="flex-end"
            xs={12}
            style={{
              height: 30,
              marginBottom: 10,
              marginTop: 4,
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: 16,
              paddingRight: 8,
            }}
          >
            <Typography variant="h3">Stop Project</Typography>
            <IconButton onClick={this.closeHandleDialog}>
              <Clear />
            </IconButton>
          </Grid>
          <Box
            style={{ padding: "24px 16px 16px 24px" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Grid container justify="center">
              <Grid item xs={12} style={{ display: "flex" }}>
                <Typography variant="h4">
                  Are you sure you want disable this project? If you disable
                  this project then all of its services will be terminated
                  permanently.
                </Typography>
              </Grid>
              <Box
                width="100%"
                height="1em"
                style={{ height: "64px", width: "48px" }}
              ></Box>
            </Grid>
            <DialogActions style={{ padding: 0 }}>
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                onClick={() => {
                  this.closeHandleDialog();
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <KycDialog
          toggleBvbpDialog={this.toggleKycDialog}
          bvbpDialog={this.state.kycDialog}
          onKycInfoSave={this.KycSubmitHandler}
          defaultData={{}}
        />

        {this.state.wabaDialog && (
          <WabaForm
            showDialog={this.state.wabaDialog}
            toggleDialog={this.toggleWABADialog}
            assistantDetails={this.state.assistant.apiFormDetails}
            setSnackbar={this.setSnackbar}
          />
        )}

        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
  },
  tabContainer: {
    background: "white",
    boxShadow: "0 0 12px rgb(171 170 170 / 21%)",
  },
  tableContainer: {
    background: "white",
    borderRadius: 8,
    padding: 24,
  },
  detailContainer: {
    top: "60px",
    height: "calc(100vh - 260px)",
    width: "100%",
    // overflowY: "scroll",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      // position top + bottombar height + bottom padding + navbar
      height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  tabIndicator: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
  },
  cells: {
    padding: 24,
    color: "grey",
    borderBottom: "1px solid #ecebeb",
  },
  textField: {
    width: 200,
    marginRight: 8,
    height: 42,
  },
  descriptionTextField: {
    width: 240,
    marginRight: 8,
    whiteSpace: "pre-line",
  },
  plandialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // height: "100%",
        // maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        // height: "unset",
        // maxHeight: "unset",
        // minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
  urlWrapper: {
    display: "flex",
    alignItems: "center",
    background: "white",
    overflow: "hidden",
  },
  urlLink: {
    border: `1px solid ${theme.palette.primary.main}`,
    overflowX: "scroll",
    whiteSpace: "nowrap",
    padding: "9px 16px",
    fontWeight: "600",
    background: "rgb(70, 20, 134, 0.1)",
    color: theme.palette.primary.main,
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    width: "200px",
    fontSize: "14px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  copyLink: {
    background: theme.palette.primary.main,
    padding: "10px 16px",
    color: "white",
    fontWeight: "600",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    fontSize: "14px",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.9,
    },
  },
  roundedBox2: {
    // width: "100%",
    [theme.breakpoints.down("sm")]: {
      // height: "auto",
      borderRadius: "12px",
      marginRight: "0px",
    },
    height: "100%",
    backgroundColor: "white",
    border: "1px black",
    borderRadius: "8px",
    // margin: "0px 8px",
    padding: "1.5em 1.5em 1.5em 1.5em",
    marginBottom: "2em",
  },
  roundedBox3: {
    // height: "45px",
    borderRadius: "12px",
    backgroundColor: "rgb(237, 226, 252)",
    border: "1px black",
    margin: "1em 0em",
    padding: "1em 2em",
    flexWrap: "nowrap",
    lineBreak: "anywhere",
  },
  container: {
    background: "rgb(70 20 134/0.1)",
    borderRadius: "15px",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
});

// export default withStyles(styles)(withRouter(AssistantsDetail));

const connectAssistantsDetail = connect(
  (state) => ({
    assistants: state.assistants.assistants,
    partner: state.partner.partner,
    planFamilies: state.planFamilies.allPlanFamilies,
    wccPlans: state.wccPlans.allWccPlans,
    whatsappPaymentPlans: state.whatsappPaymentsPlans.allPlans,
    tenantDetails: state.tenant.tenant,
    partnerAgents: state.partnerAgents.partnerAgents,
    user: state.login.user,
  }),
  {
    editAssistants,
    fetchPartnerDetails,
    uploadMedia,
  }
)(AssistantsDetail);

export default withStyles(styles)(withRouter(connectAssistantsDetail));
